<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="mt-1 mb-1">
                <!-- <v-card class="rounded-xl"> -->
                    <v-card-title class="pt-1 pb-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="4" md="4">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="vendor"
                                    :items="vendors"
                                    item-value="vendor_id"
                                    item-text="vendor_name"
                                    label="Vendor"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="currency"
                                    :items="currencies"
                                    item-value="curr_id"
                                    item-text="curr_id"
                                    label="Currency Type"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="4" md="4" class="mt-1">
                                <v-btn
                                    class="mr-2"
                                    color="error"
                                    elevation="2"
                                    large
                                    rounded
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                <!-- </v-card> -->
            </v-col>
            <v-col cols="12" class="mt-1 mb-1">
                <v-divider color="#000" class="mt-1 mb-1"></v-divider>
            </v-col>            
            <v-col cols="12" style="padding-bottom: 100px" class="mt-1 mb-1">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <div v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row  justify="center" align="center">
                            
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card
                                    class="mx-auto"
                                >
                                    <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                        Summary
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Debt Report</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>

                                    <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        class="text-h5"
                                        cols="3"
                                        >
                                         {{summaryInv.invoice ? summaryInv.invoice : 0}} <span>Inv Total</span> 
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <v-list-item>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-currency-usd</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-subtitle>Kwitansi Total : <span style="float: right;">{{summaryInv.kwitansi ? summaryInv.kwitansi : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <v-list-item>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-currency-usd-off</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-subtitle>Paid Total : <span style="float: right;">{{summaryInv.payment ? summaryInv.payment : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <v-list-item>
                                                <v-list-item-icon>
                                                    <v-icon>mdi-cash</v-icon> 
                                                </v-list-item-icon>
                                                <v-list-item-subtitle>Debt Remaining : <span style="float: right;">{{summaryInv.hutang ? summaryInv.hutang : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="raw_materials"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <v-dialog v-model="dialog" max-width="1400px">                
                    <v-card>
                        <v-card-title>Detail</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            :headers="header_details"
                                            :items="debt_details"
                                            :options.sync="option_details"
                                            :loading="loading_details"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_details"
                                            :page.sync="pagination_details"
                                            page-count="10"
                                            class="elevation-1"    
                                        >
                                            <template v-slot:[`header.dt_inv`]="{ header }">
                                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                                <i v-if="sortDetailBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.tr_id`]="{ header }">
                                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                                <i v-if="sortDetailBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.inv_id`]="{ header }">
                                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                                <i v-if="sortDetailBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'KBT Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Debt Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            vendors: [],
            vendor: '',
            currencies:[],
            currency: '',
            display: 'none',
            loading_loader: false,
            raw_materials: [],
            search: null,
            headers: [
                {
                    text: 'Vendor',
                    align: 'start',
                    sortable: false,
                    value: 'vendor_name',
                },
                { text: 'Currency', value: 'curr_id', sortable: false },
                { text: 'Invoice', value: 'invoice', sortable: false },
                { text: 'Kwitansi', value: 'kwitansi', sortable: false },
                { text: 'Payment', value: 'payment', sortable: false },
                { text: 'Debt', value: 'hutang', sortable: false },
                { text: 'Action', value: 'action', sortable: false }
            ],
            totalItems: 10,
            pagination: 1,
            options: {},
            order_id: '',
            no_faktur: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: '',
            summaryInv: {},
            dialog: false,
            detailItem:{},
            header_details:[
                { text: 'Date Inv', value: 'dt_inv', sortable: false },
                {
                    text: 'Vendor',
                    align: 'start',
                    sortable: false,
                    value: 'vendor_name',
                },
                { text: 'TR ID', value: 'tr_id', sortable: false },
                { text: 'Inv ID', value: 'inv_id', sortable: false },
                { text: 'Pay term', value: 'pay_term_desc', sortable: false },
                { text: 'Curr', value: 'curr_id', sortable: false },
                { text: 'Amt Subtotal', value: 'amt_subtotal', sortable: false },
                { text: 'Amt Disc', value: 'amt_disc', sortable: false },
                { text: 'Amt Ppn', value: 'amt_ppn', sortable: false },
                { text: 'Amt Total', value: 'amt_total', sortable: false },
                { text: 'Amt Kwitansi', value: 'amt_kwitansi', sortable: false },
                { text: 'Amt Paid', value: 'amt_paid', sortable: false },
                { text: 'Amt Paid Disc', value: 'amt_paid_disc', sortable: false },
                { text: 'Amt Debt', value: 'hutang', sortable: false }
            ],
            debt_details: [],
            option_details: {},
            loading_details: false,
            totalItem_details: 10,
            pagination_details: 1,
            sortBy: 'desc',
            sortName: '',
            sortDetailBy: 'desc',
            sortDetailName: ''
        }
    },
    mounted(){
        this.getMaterial()
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getMaterial(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/hutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.vendors = res.data.vendor
                this.currencies = res.data.currency
            });
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.loading_loader = true
            this.display = 'block'

            this.summary()

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/hutang?txtVendor=${this.vendor ? this.vendor : ''}&txtCurrency=${this.currency ? this.currency : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false

                this.raw_materials = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        async summary(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/hutang/summary?txtVendor=${this.vendor ? this.vendor : ''}&txtCurrency=${this.currency ? this.currency : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.summaryInv = res.data
            })
        },
        showItem(item){
            this.dialog = true
            this.detailItem = item
            this.debt_details = []
            this.getDetail(1, 10, item)
        }, 
        sortByFunc(sortBy){
            this.sortDetailBy = this.sortDetailBy === 'desc' ? 'asc' : 'desc';
            this.sortDetailName = sortBy
            this.getDetail(1, 10)
        },
        async getDetail(page = 1, itemsPerPage = 10, item){
            this.loading_details = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/hutang/detail?txtVendor=${this.detailItem.vendor_id ? this.detailItem.vendor_id : ''}&sort=${this.sortDetailBy ? this.sortDetailBy : 'desc'}&sortName=${this.sortDetailName ? this.sortDetailName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_details = false

                this.debt_details = res.data.data
                this.totalItem_details = res.data.total
                this.pagination_details = res.data.current_page
            })
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display != 'none') {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true
        },
        option_details:{
            handler () {
                const { page, itemsPerPage } = this.option_details
                if (this.detailItem) {
                    this.getDetail(page, itemsPerPage, this.detailItem)
                }
            },
            deep: true
        }
    }
}
</script>